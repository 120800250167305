import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'academic-staff',
  templateUrl: './academic-staff.component.html',
  styleUrls: ['./academic-staff.component.css']
})
export class AcademicStaffComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
