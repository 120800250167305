import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AcademicsComponent } from './academics/academics.component';
import { BeyondAcademicsComponent } from './beyond-academics/beyond-academics.component';
import { ExtraCurricularActivitiesComponent } from './beyond-academics/extra-curricular-activities/component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { ResourcesComponent } from './resources/resources.component';
import { StudentsComponent } from './students/students.component';
import { AluminiComponent } from './alumini/alumini.component';
// import { SidebarEffects } from './sidebar/store/sidebar-effects';
import { ContactComponent } from './contact/contact.component';
import { NewsComponent } from './news/news.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { ManagementTeamComponent } from './about/management-team/management-team.component';
import { FacultyComponent } from './about/faculty/faculty.component';
import { FoundersComponent } from './about/founders/founders.component';
import { PrincipalsComponent } from './about/principals/principals.component';
import { PrincipalDeskComponent } from './about/principal-desk/principal-desk.component';
import { AcademicStaffComponent } from './about/academic-staff/academic-staff.component';
import { HistoryComponent } from './about/history/history.component';
import { CoursesOfferedComponent } from './academics/courses-offered/courses.component';
import { DisciplineConductComponent } from './academics/discipline-conduct/discipline-conduct.component';
import { CalendarComponent } from './academics/calendar/calendar.component';
import { SportsComponent } from './achievements/sports/sports.component';
import { CulturalComponent } from './achievements/cultural/cultural.component';
import { VisionMissionComponent } from './about/vision-mission/vision-mission.component';
import { AchievementsAcademicsComponent } from './achievements/achievements-academics/achievements-academics.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'academics', component: AcademicsComponent },
  { path: 'beyond-academics', component: BeyondAcademicsComponent },
  { path: 'extra-curricular-activities', component: ExtraCurricularActivitiesComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'alumini', component: AluminiComponent },
  { path: 'photo-gallery', component: PhotoGalleryComponent },
  { path: "resources", component: ResourcesComponent },
  { path: "students", component: StudentsComponent },
  { path: "news", component: NewsComponent },
  { path: "achievements", component: AchievementsComponent },
  { path: "about/management-team", component: ManagementTeamComponent },
  { path: "about/history", component: HistoryComponent },
  { path: "about/faculty", component: FacultyComponent },
  { path: "about/founders", component: FoundersComponent },
  { path: "about/principals", component: PrincipalsComponent },
  { path: "about/principal-desk", component: PrincipalDeskComponent },
  { path: "about/academic-staff", component: AcademicStaffComponent },
  { path: "academics/courses-offered", component: CoursesOfferedComponent },
  { path: "academics/discipline-conduct", component: DisciplineConductComponent },
  { path: "academics/calendar", component: CalendarComponent },
  { path: "achievements/sports", component: SportsComponent },
  { path: "achievements/cultural", component: CulturalComponent },
  { path: "about/vision-mission", component: VisionMissionComponent },
  { path: "achievements/achievements-academics", component: AchievementsAcademicsComponent },
];




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    AcademicsComponent,
    BeyondAcademicsComponent,
    ExtraCurricularActivitiesComponent,
    PhotoGalleryComponent,
    ResourcesComponent,
    StudentsComponent,
    AluminiComponent,
    ContactComponent,
    NewsComponent,
    AchievementsComponent,
    ManagementTeamComponent,
    FacultyComponent,
    FoundersComponent,
    HistoryComponent,
    PrincipalsComponent,
    PrincipalDeskComponent,
    AcademicStaffComponent,
    CoursesOfferedComponent,
    DisciplineConductComponent,
    CalendarComponent,
    SportsComponent,
    CulturalComponent,
    VisionMissionComponent,
    AchievementsAcademicsComponent

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    // EffectsModule.forRoot([SidebarEffects])
  ],
  providers: [


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
