import { Component, OnInit } from '@angular/core';
import{AppServiceService}from'../app-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
public news=[];
  constructor(private service:AppServiceService,public route: ActivatedRoute) { }

  ngOnInit() {
    this.news =this.service.getnews();
    
  }

}
