import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor() { }
  public selectedNewsId : string="";
  getnews()
  {
       return [
         
    { 
      title:"Acknowledgement Of Outstanding Performance",
	    Image: ['assets/img/acknowledgement1.jpeg','assets/img/acknowledgement2.jpeg','assets/img/acknowledgement3.jpeg','assets/img/acknowledgement4.jpeg'], 
      newsId:'newsid1'
  },
    { 
    title:"PESIT  institution  honor  for  excellence  in the  Science  stream",
	  Image: ['assets/img/PESIT1.jpeg'],
    newsId:'newsid2'
  },
    { 
     title:"Cash  Awards  For Academic Excellence",
     Image: ['assets/img/awards1.jpeg','assets/img/awards2.jpeg',],
     newsId:'newsid3'
    },
    { 
    title:"Quiz Competition",
	  Image: ['assets/img/quiz competition.png','assets/img/quizcompetition1.jpeg','assets/img/quizcompetition2.jpeg'],
    newsId:'newsid4'
  },
    { 
      title:"Annual Cultural Activities",
	  Image: ['assets/img/annual culturalactivities1.jpeg','assets/img/annual culturalactivities2.jpeg','assets/img/annual culturalactivities3.jpeg','assets/img/annual culturalactivities4.jpeg'], 
    newsId:'newsid5'
  },
	{
     title:"Stalls  At Annual Fest",
	  Image: ['assets/img/stalls1.jpeg','assets/img/stalls2.jpeg','assets/img/stalls3.jpeg','assets/img/stalls4.jpeg'], 
    newsId:'newsid6'
  },
	{ 
    title:"Annual Science Exhibition",
	  Image: ['assets/img/scienceexhibition1.jpeg','assets/img/scienceexhibition2.jpeg','assets/img/scienceexhibition3.jpeg','assets/img/scienceexhibition4.jpeg','assets/img/scienceexhibition5.jpeg','assets/img/scienceexhibition6.jpeg'], 
    newsId:'newsid7'
  },  
	{
    title:"Commerce Exhibition",
	  Image: ['assets/img/commerceexibition1.jpeg','assets/img/commerceexibition2.jpeg'], 
    newsId:'newsid8'
  },
    { 
      title:"Sports Activities",
	  Image: ['assets/img/Sports Activities1.png','assets/img/Sports Activities2.png'], 
    newsId:'newsid9'
  }, 
    {
    title:"Visit  to  Nehru  Centre  for  Advanced  Scientific  Research, Jakkur",
	  Image: ['assets/img/Nehru  Centre  1.jpeg','assets/img/Nehru  Centre  2.jpeg'], 
    newsId:'newsid10'
  },
    { 
      title:"Visit  to  KMF, BANGALORE",
	  Image: ['assets/img/kmf.jpeg'], 
    newsId:'newsid11'
  },
	{ 
    title:"Visit to UNIBIC",
	  Image: ['assets/img/unibic1.jpeg','assets/img/unibic2.jpeg','assets/img/unibic3.jpeg','assets/img/unibic4.jpeg','assets/img/unibic5.jpeg'], 
    newsId:'newsid12'
  },
	{ 
    title:"Assembly",
	  Image: ['assets/img/assembly.jpeg'], 
    newsId:'newsid13'
  },
    { 
      title:"Library",
	  Image: ['assets/img/library.jpeg'], 
    newsId:'newsid14'
  }, 
    { 
      title:"Audio  Visual  Class Room",
	  Image: ['assets/img/audio visualclass .jpeg'], 
    newsId:'newsid15'
  },
{ 
  title:"Computer  Science  Lab",
	  Image: ['assets/img/cslab.jpeg'], 
    newsId:'newsid16'
  },
	  { 
      title:"Physics Lab",
	  Image: ['assets/img/phylab1.jpeg','assets/img/phylab2.jpeg','assets/img/phylab3.png'], 
    newsId:'newsid17'
  },
	  { 
      title:"Chemistry Lab",
	  Image: ['assets/img/chemlab.jpeg'], 
    newsId:'newsid18'
  },
	   { title:"Biology Lab",
	  Image: ['assets/img/biolab.jpeg'], 
    newsId:'newsid19'
  },
	  { title:"Financial Education Workshop By Sebi",
	  Image: ['assets/img/SEBI1.jpeg','assets/img/SEBI2.jpeg','assets/img/SEBI3.jpeg'], 
    newsId:'newsid20'
  },
	  { title:"Eye Donation Camp",
	  Image: ['assets/img/eye donationcamp.jpeg'], 
    newsId:'Knewsid21'
  },
	   { title:"Chakravarthi Sulibele",
	  Image: ['assets/img/chakravarthi sulibele.png'], 
    newsId:'Knewsid22'
  },
	   { title:"Lecture Series By Students",
	  Image: ['assets/img/lecture seriesstudents1.jpeg','assets/img/lecture seriesstudents2.jpeg'], 
    newsId:'newsid23'
  },
	  { title:"B. PARTHASARATHI  AUDITORIUM",
	  Image: ['assets/img/b. parthasarathi  auditorium.jpeg'], 
    newsId:'newsid24'
  },
	  { title:"Lecture Series",
	  Image: ['assets/img/lecture series1.jpeg','assets/img/lecture seriesstudents2.jpeg'], 
    newsId:'newsid25'
  }
	  
       ]
      }



}
