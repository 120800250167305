import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beyond-academics',
  templateUrl: './beyond-academics.component.html',
  styleUrls: ['./beyond-academics.component.css']
})
export class BeyondAcademicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
