import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef;

  ngAfterViewInit() {
    this.playVideo();
  }

  playVideo() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;
    video.play().catch(error => console.log("Autoplay blocked:", error));
  }

  // Listen for a click to manually start the video if autoplay is blocked
  @HostListener('window:click')
  onUserInteraction() {
    this.playVideo();
  }

  constructor(private router: Router) { }

  ngOnInit() {
    this.sub_store();
    
  }
  sub_store(){
  	//  this.router.navigate(['/home'])
  }

  showBrochure() {
    window.open('assets/brochure.pdf', '_blank')
  }

  openYouTube() {
    window.open('https://youtube.com/@vvspucgoldenjubilee', '_blank')
  }

  openMagazine() {
    window.open('assets/pdf/2025/VidyaSuvarna-final.pdf', '_blank')
  }

  openAlumniRegistrationForm() {
    window.open('https://forms.gle/q4JE9D7RqCgnUZrW8', '_blank')
  }

  openRegistrationForm() {
    window.open('https://forms.gle/q4JE9D7RqCgnUZrW8https://docs.google.com/forms/d/18R7GprImoUwWBrya4HjT9DNpFL9xcnmzjCKNWaXH2iU/edit', '_blank')
  }
}
