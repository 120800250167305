import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'founders',
  templateUrl: './founders.component.html',
  styleUrls: ['./founders.component.css']
})
export class FoundersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
