import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'principal-desk',
  templateUrl: './principal-desk.component.html',
  styleUrls: ['./principal-desk.component.css']
})
export class PrincipalDeskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
