import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-achievements-academics',
  templateUrl: './achievements-academics.component.html',
  styleUrls: ['./achievements-academics.component.css']
})
export class AchievementsAcademicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
